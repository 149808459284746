






































































import { KlevuConfig, KlevuFetch, search, suggestions, searchCategory, trendingProducts, KlevuPackFetchResult, KlevuKMCSettings } from "@klevu/core";

KlevuConfig.init({
  url: "https://eucs12.ksearchnet.com/cs/v2/search",
  apiKey: "klevu-15523907922889429",
});

import { SfButton, SfSearchBar, SfLink } from '@storefront-ui/vue';
import {
  defineComponent, ref, watch, useRoute, useRouter, useContext
} from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import SvgImage from '~/components/General/SvgImage.vue';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { Products } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'SearchBar',
  components: {
    SfSearchBar,
    SfButton,
    SvgImage,
    SfLink
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
  },
  emits: ['set-is-open', 'set-search-results', 'close-mobile-search'],
  setup(props, { emit }) {
    const term = ref('');
    const searchResults = ref([]);
    const searchSuggestions = ref([]);
    const searchCategories = ref([]);

    const klevuSettings = ref({});
    const bannerKlevu = ref({});

    const searchedTerm = ref();
    const route = useRoute();
    const router = useRouter();
    const { app } = useContext();
    const term2 = "camera"

    const klevuSearch = (searchTerm: any) => {
      if (document) {
        document.getElementById("search_container").classList.add("search_active");
        /* document.body.classList.add('no-scroll'); */
      }
      fetchKlevuSettings(searchTerm);
      searchedTerm.value = searchTerm;
      if (searchTerm.length > 3) {        
        const results = KlevuFetch(search(searchTerm, { limit: 6 })).then((response) => {
          const dataToTransferFrontend = KlevuPackFetchResult(response);
          searchResults.value = dataToTransferFrontend.queryResults[0].records;

          console.log(searchResults.value.length);
          if(process.client){
            //@ts-ignore
            window.dataLayer = window.dataLayer || [];
            //@ts-ignore
            window.dataLayer.push({
              event: "klevu_quick_search",
              klevu_search_results_num: searchResults.value.length,        
            });
          }
        });
        const results2 = KlevuFetch(suggestions(searchTerm, { limit: 6 })).then((response) => {
          const dataToTransferFrontend = KlevuPackFetchResult(response);
          searchSuggestions.value = dataToTransferFrontend.suggestionResults[0].suggestions;
          if(process.client){
            //@ts-ignore
            window.dataLayer = window.dataLayer || [];
            //@ts-ignore
            window.dataLayer.push({
              event: "klevu_recommendations",
              klevu_search_results_num: searchSuggestions.value.length,        
            });
          }
          
        });
        const results3 = KlevuFetch(searchCategory(searchTerm, { limit: 6 })).then((response) => {
          const dataToTransferFrontend = KlevuPackFetchResult(response);
          searchCategories.value = dataToTransferFrontend.queryResults[0].records;
        });
      }else{
        const results = KlevuFetch(trendingProducts({ limit: 6})).then((response) => {
          const dataToTransferFrontend = KlevuPackFetchResult(response);
          searchResults.value = dataToTransferFrontend.queryResults[0].records;

          if(process.client){
            //@ts-ignore
            window.dataLayer = window.dataLayer || [];
            //@ts-ignore
            window.dataLayer.push({
              event: "klevu_quick_search",
              klevu_search_results_num: searchResults.value.length,        
            });
          }
        });  
        const results2 = KlevuFetch(suggestions('', { limit: 6})).then((response) => {
          const dataToTransferFrontend = KlevuPackFetchResult(response);
          searchSuggestions.value = dataToTransferFrontend.suggestionResults[0].suggestions;
        });
      }
    };
    const fetchKlevuSettings = async (searchTerm) => {
      try {
        const settings = await KlevuKMCSettings();
        klevuSettings.value = settings;
        bannerKlevu.value = getBannerKlevu(searchTerm, settings);
      } catch (error) {
        console.log("Error fetching Klevu KMC Settings:", error);
      }
    }

    /* const getBannerKlevu = (searchTerm, klevusettings) => {
      console.log(searchTerm);
      console.log(klevusettings);

      if (!klevusettings?.banner?.klevu_banner) {
          return null;
      }

      // Find the banner that matches the search term
      const banner = klevusettings.banner.klevu_banner.find(b =>
          b.showForTerms && b.showForTerms.includes(searchTerm)
      );

      return banner || null;
   }; */

   const getBannerKlevu = (searchTerm, klevuSettings) => {
        if (!klevuSettings?.banner?.klevu_banner) {
            return null;
        }
      
        // Get current date without time for easier comparison
        const today = new Date();
        today.setHours(0, 0, 0, 0);
      
        // Find a matching banner with valid dates
        const banner = klevuSettings.banner.klevu_banner.find(b => {
            // Must match the search term in showForTerms
            if (!b.showForTerms || !b.showForTerms.includes(searchTerm)) {
                return false;
            }
          
            // Convert banner start/end dates to Date objects
            // Replacing "/" with "-" for better parsing support
            const startDate = new Date(b.startDate.replace(/\//g, "-"));
            const endDate = new Date(b.endDate.replace(/\//g, "-"));
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);
          
            // Check if today falls within the date range
            return today >= startDate && today <= endDate;
        });
      
        return (banner || null);
    };

    const searchImageUrl = (value) => {
      return value.replace('needtochange', 'pub');
    };

    const { getProductList } = useProduct();

    const showSearch = () => {
      if (!props.isSearchOpen) {
        emit('set-is-open', true);
        
        if (document) {
          /* document.body.classList.add('no-scroll'); */
        }
      }
    };

    const removeHtml = (html) => {
      return html.replace(/<(.|\n)*?>/g, '');     
    };

    const mpexshowSearch = () => {
      //klevuSearch(searchedTerm);
      if (document) {
        document.getElementById("search_container").classList.add("search_active");
        /* document.body.classList.add('no-scroll'); */
      }
      return;
    };

    const mpexhideSearch = () => {
      if (document) {
        document.getElementById("search_container").classList.remove("search_active");
        /* document.body.classList.remove('no-scroll'); */
      }
    };

    const hideSearch = () => {
      if (props.isSearchOpen) {
        emit('set-is-open', false);
        emit('set-search-results', null);
        if (document) {
          /* document.body.classList.remove('no-scroll'); */
        }
      }
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const closeSearch = (event: MouseEvent) => {
      if (document) {
        const searchResultsEl = document.querySelector('.search');
        const closeTriggerElement = event.target as HTMLElement;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          hideSearch();
          term.value = '';
        }
      } else {
        hideSearch();
        term.value = '';
      }
    };

    const rawHandleSearch = async (searchTerm: string) => {
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) return;

      // M2-579
      const productList: Products = await getProductList({
        pageSize: props.itemsPerPage,
        currentPage: 2,
        search: term.value
      }) as unknown as Products;

      emit('set-search-results', productList!.items);
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 1000);

    const handleKeydownEnter = (searchTerm: string) => {
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      debouncedHandleSearch.cancel();
      rawHandleSearch(searchTerm);
    };

    const search_text = ref('');
    const submitForm = async (url, input_text) => {
      if(input_text) {
        var path = url;
        var query = { term: input_text };
        hideSearch();
        mpexhideSearch();
        search_text.value = '';
        await router.push({path, query});
      }
    };

    const handleLinkClick = (event) => {
      const anchor = event.target.closest('a');
      if (anchor) {
        event.preventDefault();
        const href = anchor.getAttribute('href');
        if (href) {
          router.push(href);
        }
      }
    };

    watch(route, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        search_text.value = '';
        mpexhideSearch();
        hideSearch();
      }
    });

    const close_mobile_search = () => {
      emit('close-mobile-search');
    }

    return {
      closeSearch,
      showSearch,
      mpexshowSearch,
      mpexhideSearch,
      hideSearch,
      toggleSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      klevuSearch,
      term,
      searchResults,
      searchSuggestions,
      searchCategories,
      searchImageUrl,
      removeHtml,
      submitForm,
      search_text,
      handleLinkClick,
      bannerKlevu,
      close_mobile_search
    };
  },
});

